<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title=this.pageTitle
      :subtitle=this.pageSubTitle
      :hide-buttons="true"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="name" class="w-full select-large">Nombre</label>
          <vs-input id="name" name="name" v-model="name" class="w-full"/>
        </div>
      </div>

      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full">
          <label for="periodicity" class="w-full select-large">Frecuencia</label>
          <v-select
            id="periodicity"
            name="periodicity"
            label="name"
            index="id"
            :options="periodicities"
            :placeholder="'Seleccione...'"
            :searchable="true"
            v-model="periodicity"
            class="w-full"
          />
          <br/>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label for="execution_hour" class="w-full select-large">Hora de ejecución</label>
          <flat-pickr
            :config="configDateTimePicker"
            id="execution_hour" name="execution_hour" placeholder="Hora de ejecución"
            v-model="execution_hour"
            v-validate="'required'"
            class="w-full flatpickr flatpickr-input"
          />
          <span class="text-danger">{{ errors.first("start_date") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row mt-1">
        <div class="total vx-col w-full">
          <label class="w-full select-large">Destinatarios</label>
          <vs-chips
            color="rgb(145, 32, 159)" placeholder="" v-model="recipients"
            remove-icon="delete_forever" class="mt-1 recipients-list"
          >
            <vs-chip
              :key="recipient"
              @click="remove(recipient)"
              v-for="recipient in recipients" closable close-icon="delete">
              {{ recipient }}
            </vs-chip>
          </vs-chips>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToScheduledReportsList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateReport">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import EntriesClient from "../../utils/entriesClient";
  import EntriesSettingsClient from "../../utils/entriesSettingsClient";
  import ReportsClient from "../../utils/reportsClient";
  import Notifier from "./../../utils/notification";

  import {Spanish} from 'flatpickr/dist/l10n/es.js';
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";

  let notifier = new Notifier();
  const ENTRY_CLIENT = new EntriesClient("promotions");
  const ENTRY_SETTINGS_CLIENT = new EntriesSettingsClient("promotions");
  const REPORTS_CLIENT = new ReportsClient("promotions");


  export default {
    data() {
      return {
        pageTitle: "Reporte Programado",
        pageSubTitle: "",
        configDateTimePicker: {
          minuteIncrement: 0,
          defaultHour: 0,
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:00",
        },
        periodicityMapper: {
          every_monday: "Todos los lunes",
          every_tuesday: "Todos los martes",
          every_wednesday: "Todos los miercoles",
          every_thursday: "Todos los jueves",
          every_friday: "Todos los viernes",
          every_saturday: "Todos los sábados",
          every_sunday: "Todos los domingos",
          first_day_of_each_month: "El primer día de cada mes",
          last_day_of_each_month: "El último día de cada mes",
          specific_dates: "Fechas personalizadas",
        },
        periodicities: [
          {id: "every_monday", name: "Todos los lunes"},
          {id: "every_tuesday", name: "Todos los martes"},
          {id: "every_wednesday", name: "Todos los miercoles"},
          {id: "every_thursday", name: "Todos los jueves"},
          {id: "every_friday", name: "Todos los viernes"},
          {id: "every_saturday", name: "Todos los sábados"},
          {id: "every_sunday", name: "Todos los domingos"},
          {id: "first_day_of_each_month", name: "El primer día de cada mes"},
          {id: "last_day_of_each_month", name: "El último día de cada mes"},
          // {id: "specific_dates", name: "Fechas personalizadas"},
        ],
        id: null,
        name: "",
        periodicity: null,
        recipients: [],
        execution_hour: "00:00",
      };
    },

    mounted() {
      if (this.$route.params.reportId !== undefined) {
        this.$vs.loading();
        this.loadReportData().then(() => {
          this.$vs.loading.close();
        });
      }
    },

    methods: {
      remove (recipient) {
        this.recipients.splice(this.recipients.indexOf(recipient), 1)
      },

      async loadReportData() {
        await REPORTS_CLIENT.retrieve({
          pathParameters: {entryId: this.$route.params.entryId, scheduledReportId: this.$route.params.reportId}
        })
          .then(response => {
            this.id = response.data.id;
            this.name = response.data.name;
            this.recipients = response.data.recipients;
            this.periodicity = {
              id: response.data.periodicity,
              name: this.periodicityMapper[response.data.periodicity]
            }
            this.execution_hour = `${response.data.execution_hour.toString().padStart(2, "0")}:00`
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateReport() {
        this.$vs.loading();
        let data = {
          name: this.name,
          periodicity: this.periodicity.id,
          recipients: this.recipients,
          execution_hour: parseInt(this.execution_hour.split(":")[0]),
        }
        if (this.id !== null && this.id !== undefined && this.id !== "") {
          await REPORTS_CLIENT.update({
            pathParameters: {entryId: this.$route.params.entryId, scheduledReportId: this.id},
            data: data
          })
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToScheduledReportsList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        } else {
          await REPORTS_CLIENT.create({pathParameters: {entryId: this.$route.params.entryId}, data: data})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToScheduledReportsList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      goToScheduledReportsList() {
        this.$router.push({name: "list-scheduled-reports-promotion", params: {entryId: this.$route.params.entryId}});
      },
    },
    components: {
      FormWizard,
      TabContent,
      flatPickr,
      "v-select": vSelect
    }
  };
</script>

<style scoped>
  .recipients-list {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
</style>